/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import InsiderLink from "../../containers/InsiderLinkWithSubscription";
import { CrossIcon } from "../ui/icons/CloseIcon";
import { generateGooutLink } from "../../helpers/linkGenerators";
//styles
const BannerContainer = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
});
const BannerHome = css({
    display: "block",
    marginBottom: "25px",
    position: "static",
    transform: "none"
});
const InsiderLogoMobileStyles = css `
	background: transparent url(https://res.cloudinary.com/dwzmsvp7f/image/upload/w_320/Insider_Logo_Colour_rrag9p.png) 0/contain no-repeat;
	width: 160px;
	height: 32px;
`;
export const MobileMenuHeader = ({ disableMobileMenu, selectedCity }) => {
    return (<div css={BannerHome}>
			<div css={BannerContainer}>
				<InsiderLink onClick={disableMobileMenu} href={generateGooutLink(selectedCity)} css={InsiderLogoMobileStyles}/>
				<div onClick={disableMobileMenu}>
				<CrossIcon />
				</div>
			</div>
		</div>);
};
